import {Container} from './Container'
import {useTranslation} from "react-i18next";
import i18n from "../i18n";
import {Disclosure, DisclosureButton, DisclosurePanel} from "@headlessui/react";
import {MinusSmallIcon, PlusSmallIcon} from "@heroicons/react/20/solid";

const faqs = []

export function Faqs() {
    const {t} = useTranslation();

    return (
        <section
            id="faqs"
            aria-labelledby="faqs-title"
            className="border-t border-gray-200 py-20 sm:py-32"
        >
            <Container>
                <div className="mx-auto max-w-2xl lg:mx-0">
                    <h2
                        id="faqs-title"
                        className="text-3xl font-medium tracking-tight text-gray-900"
                    >
                        {t(globalThis.AUDIENCE + '.homepage.faqs.header')}
                    </h2>
                    <p className="mt-2 text-lg text-gray-600">
                        {t(globalThis.AUDIENCE + '.homepage.faqs.paragraph')}
                        <a
                            href={t(globalThis.AUDIENCE + '.homepage.faqs.paragraph_link_url')}
                            className="text-gray-900 underline"
                        >
                            {t(globalThis.AUDIENCE + '.homepage.faqs.paragraph_link')}
                        </a>
                        .
                    </p>
                </div>
                <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                    {Object.values(t(globalThis.AUDIENCE + '.homepage.faqs.questions', {returnObjects: true}) || {}).map((faq) => (
                        <Disclosure key={faq.question} as="div" className="pt-6">
                            <dt>
                                <DisclosureButton
                                    className="group flex w-full items-start justify-between text-left text-gray-900">
                                    <span className="text-base font-semibold leading-7">{faq.question}</span>
                                    <span className="ml-6 flex h-7 items-center">
                      <PlusSmallIcon aria-hidden="true" className="h-6 w-6 group-data-[open]:hidden"/>
                      <MinusSmallIcon aria-hidden="true" className="h-6 w-6 [.group:not([data-open])_&]:hidden"/>
                    </span>
                                </DisclosureButton>
                            </dt>
                            <DisclosurePanel as="dd" className="mt-2 pr-12">
                                <p className="font-thin leading-7">{faq.answer}</p>
                            </DisclosurePanel>
                        </Disclosure>
                    ))}
                </dl>
                {/*<ul*/}
                {/*    role="list"*/}
                {/*    className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 sm:mt-20 lg:max-w-none lg:grid-cols-3"*/}
                {/*>*/}
                {/*    {Object.values(t(globalThis.AUDIENCE + '.homepage.faqs.questions', {returnObjects: true}) || {}).map((val) => (*/}
                {/*        <div>*/}
                {/*            <p className="font-bold pb-1 text-lg">{val.question}</p>*/}
                {/*            <p className="">{val.answer}</p>*/}
                {/*        </div>*/}
                {/*    ))}*/}
                {/*</ul>*/}
            </Container>
        </section>
    )
}

function safeArray(unsafeArray) {
    console.log(i18n);
    if (Array.isArray(unsafeArray))
        return unsafeArray

    return []
}
