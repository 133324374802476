import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import {
    ArchiveBoxIcon,
    ArrowRightCircleIcon,
    ChevronDownIcon,
    DocumentDuplicateIcon,
    HeartIcon,
    PencilSquareIcon,
    TrashIcon,
    UserPlusIcon,
} from '@heroicons/react/20/solid'
import {useTranslation} from "react-i18next";
import {useEffect} from "react";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function LanguageSwitch() {
    const { t, i18n } = useTranslation()

    useEffect(() => {
        i18n.changeLanguage('en');
    }, []);


    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <MenuButton
                    className="inline-flex w-full justify-center  rounded-lg bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                    { i18n.language === "nl" && <svg className="h-6 w-6 rounded-full mr-2 shadow-md" xmlns="http://www.w3.org/2000/svg"
                         viewBox="85.5 0 342 342">
                        <script xmlns=""/>
                        <path fill="#FFF" d="M0 114h513v114H0z"/>
                        <path fill="#cd1f2a" d="M0 0h513v114H0z"/>
                        <path fill="#1d4185" d="M0 228h513v114H0z"/>
                    </svg>}
                    {i18n.language === "en" &&
                        <svg className="h-6 w-6 rounded-full mr-2 shadow-md" xmlns="http://www.w3.org/2000/svg"
                             viewBox="85.5 0 342 342">
                            <script xmlns=""/>
                            <g fill="#FFF">
                                <path d="M0 0h513v341.3H0V0z"/>
                                <path
                                    d="M311.7 230 513 341.3v-31.5L369.3 230h-57.6zM200.3 111.3 0 0v31.5l143.7 79.8h56.6z"/>
                            </g>
                            <g fill="#0052B4">
                                <path
                                    d="M393.8 230 513 295.7V230H393.8zm-82.1 0L513 341.3v-31.5L369.3 230h-57.6zm146.9 111.3-147-81.7v81.7h147zM90.3 230 0 280.2V230h90.3zm110 14.2v97.2H25.5l174.8-97.2zM118.2 111.3 0 45.6v65.7h118.2zm82.1 0L0 0v31.5l143.7 79.8h56.6zM53.4 0l147 81.7V0h-147zM421.7 111.3 513 61.1v50.2h-91.3zm-110-14.2V0h174.9L311.7 97.1z"/>
                            </g>
                            <g fill="#D80027">
                                <path d="M288 0h-64v138.7H0v64h224v138.7h64V202.7h224v-64H288V0z"/>
                                <path
                                    d="M311.7 230 513 341.3v-31.5L369.3 230h-57.6zM143.7 230 0 309.9v31.5L200.3 230h-56.6zM200.3 111.3 0 0v31.5l143.7 79.8h56.6zM368.3 111.3 513 31.5V0L311.7 111.3h56.6z"/>
                            </g>
                        </svg>}
                    <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true"/>
                </MenuButton>
            </div>

            <Transition
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <MenuItems
                    className="absolute right-0 z-10 mt-2 origin-top-right divide-y divide-gray-100 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        <MenuItem>
                            {({focus}) => (
                                <a
                                    onClick={() => i18n.changeLanguage('nl')}
                                    className={classNames(
                                        focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'group flex items-center px-4 py-2 text-sm'
                                    )}
                                >
                                    <svg className="h-6 w-6 rounded-full mr-2 shadow-md"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="85.5 0 342 342">
                                        <script xmlns=""/>
                                        <path fill="#FFF" d="M0 114h513v114H0z"/>
                                        <path fill="#cd1f2a" d="M0 0h513v114H0z"/>
                                        <path fill="#1d4185" d="M0 228h513v114H0z"/>
                                    </svg>
                                    Dutch
                                </a>
                            )}
                        </MenuItem>
                        <MenuItem>
                            {({focus}) => (
                                <a
                                    onClick={() => i18n.changeLanguage('en')}
                                    className={classNames(
                                        focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'group flex items-center px-4 py-2 text-sm'
                                    )}
                                >
                                    <svg className="h-6 w-6 rounded-full mr-2 shadow-md" xmlns="http://www.w3.org/2000/svg" viewBox="85.5 0 342 342">
                                        <script xmlns=""/>
                                        <g fill="#FFF">
                                            <path d="M0 0h513v341.3H0V0z"/>
                                            <path
                                                d="M311.7 230 513 341.3v-31.5L369.3 230h-57.6zM200.3 111.3 0 0v31.5l143.7 79.8h56.6z"/>
                                        </g>
                                        <g fill="#0052B4">
                                            <path
                                                d="M393.8 230 513 295.7V230H393.8zm-82.1 0L513 341.3v-31.5L369.3 230h-57.6zm146.9 111.3-147-81.7v81.7h147zM90.3 230 0 280.2V230h90.3zm110 14.2v97.2H25.5l174.8-97.2zM118.2 111.3 0 45.6v65.7h118.2zm82.1 0L0 0v31.5l143.7 79.8h56.6zM53.4 0l147 81.7V0h-147zM421.7 111.3 513 61.1v50.2h-91.3zm-110-14.2V0h174.9L311.7 97.1z"/>
                                        </g>
                                        <g fill="#D80027">
                                            <path d="M288 0h-64v138.7H0v64h224v138.7h64V202.7h224v-64H288V0z"/>
                                            <path
                                                d="M311.7 230 513 341.3v-31.5L369.3 230h-57.6zM143.7 230 0 309.9v31.5L200.3 230h-56.6zM200.3 111.3 0 0v31.5l143.7 79.8h56.6zM368.3 111.3 513 31.5V0L311.7 111.3h56.6z"/>
                                        </g>
                                    </svg>
                                    English
                                </a>
                            )}
                        </MenuItem>
                    </div>
                </MenuItems>
            </Transition>
        </Menu>
    )
}
