import Home from "./page";
import {Layout} from "./components/Layout";
import {Route, Routes} from "react-router-dom";


function App() {
    return (
        <Layout>
            <div className="App">
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/wbs/test" element={<h1>Hello world!</h1>}/>
                </Routes>
            </div>
        </Layout>
    );
}

export default App;
