"use client";

import {useRef, useState} from "react";

import {AnimatePresence, motion} from "framer-motion";
import {useTranslation} from "react-i18next";
import {useSearchParams} from "react-router-dom";

export function NavLinks() {
    const {t} = useTranslation();
    let [hoveredIndex, setHoveredIndex] = useState(null);
    let timeoutRef = useRef(null);

    const [searchParams, setSearchParams] = useSearchParams();

    return [
        [
            t(globalThis.AUDIENCE + ".navbar.links.features"),
            "/#features",
            undefined,
        ],
        [t(globalThis.AUDIENCE + ".navbar.links.pricing"), "/#pricing", undefined],
    ].map(([label, href, param], index) => (
        <a
            key={label}
            href={href}
            className="relative -mx-1.5 -my-2 rounded-lg px-3 py-2 text-sm text-gray-700 transition-colors delay-150 hover:text-gray-900 hover:delay-0"
            onMouseEnter={() => {
                if (timeoutRef.current) {
                    window.clearTimeout(timeoutRef.current);
                }
                setHoveredIndex(index);
            }}
            onMouseLeave={() => {
                timeoutRef.current = window.setTimeout(() => {
                    setHoveredIndex(null);
                }, 200);
            }}
        >

            <span
                className={`relative z-10 ${searchParams.get("for") === param ? "text-primary" : ""}`}
            >
        {label}
      </span>
        </a>
    ));
}
