//@ts-nocheck

import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";



i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,
        fallbackLng: "en",
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        // resources: {
        //     en: EN_TRANSLATIONS,
        //     nl: {
        //         "translation": {
        //             "general.try_it_CTA": "Probeer het nu",
        //             "navbar": {
        //                 "links": {
        //                     "features": "Functies",
        //                     "pricing": "Prijzen",
        //                     "faqs": "Veelgestelde Vragen",
        //                     entrepreneurs: "Voor Ondernemers",
        //                     investors: "Voor Investeerders & Lenders",
        //                     financeProfessionals: "Voor Boekhouders"
        //                 }
        //             },
        //             "homepage": {
        //                 "hero": {
        //                     "title": "Krijg real time inzichten & alerts over je financiën en bespreek ze met je boekhouder",
        //                     "subtitle": "Maak betere financiele beslissingen, krijg inzichten en prognoses, bespaar geld en tijd"
        //                 },
        //                 "features": {
        //                     "heading": "Het duurt minder dan een minuut om te starten",
        //                     "subheading": "PocketCFO is gebouwd voor drukke ondernemers die geen tijd hebben voor onzin. Probeer het vier weken gratis.",
        //                     "feature1.name": "Meldt je aan en vul je gegevens in",
        //                     "feature1.description": "Vul je gegevens en basisinformatie in, zodat we je leren kennen en een account kunnen aanmaken.",
        //                     "feature2.name": "Verbindt je relevante bronnen",
        //                     "feature2.description": "PocketCFO analyseert je transacties om je betere inzichten te geven over je bedrijf. Je kunt gemakkelijk verbinden met meer dan 2000 banken, beginnend in Nederland.",
        //                     "feature3.name": "Get insights and control your finances better",
        //                     "feature3.description": "Gefeliciteerd! Je ontvangt nu inzichten en meldingen over inkomsten, kosten en duurzaamheidstrends."
        //                 },
        //                 "showcase": {
        //                     "pretitle": "Snellere beslissingen",
        //                     "header": "Moe van het wachten op maandelijkse of kwartaalcijfers?",
        //                     "paragraph": "Wij geven je realtime overzicht en inzichten in al je geldstromen - en waarschuwen je over verkopen, kosten, winst, cash en duurzaamheidstrends op realtime basis."
        //                 },
        //                 "usps": {
        //                     "header": "Jij runt je bedrijf. Wij houden je alert met overzicht, inzichten en acties",
        //                     "paragraph": "We helpen je naar de financiële toekomst te kijken. Zonder jargon.",
        //                     "usp1.name": "Maandelijkse prognose en investeringsruimte",
        //                     "usp1.description": "Krijg bruikbare inzichten gebaseerd op cash bij de bank tot 18 maanden vooruit.",
        //                     "usp2.name": "Snellere beslissingen",
        //                     "usp2.description": "Krijg onmiddellijk inzichten, niet maanden na de feiten",
        //                     "usp3.name": "Praat met je data en je boekhouder",
        //                     "usp3.description": "Moe van het zoeken naar documenten, of het maken van spreadsheets om een statistiek te krijgen? Vraag het gewoon aan PocketCFO om je antwoord te krijgen",
        //                     "usp4.name": "Schattingen van de CO₂-voetafdruk",
        //                     "usp4.description": "PocketCFO schat je CO2 impact in op basis van je uitgaven - zie of je duurzamer wordt",
        //                     "usp5.name": "Gegevens worden veilig bewaard",
        //                     "usp5.description": "We behandelen financiële gegevens met uiterste zorg. Je gegevens zijn veilig in onze gegevenskluis.",
        //                     "usp6.name": "Bruikbare inzichten",
        //                     "usp6.description": "Zal je voldoende cash hebben om belasting te betalen? Voorraad vooruitbestellen? Personeel aannemen? Praat met je data en PocketCFO geeft je inzichten"
        //                 },
        //                 "cta": {
        //                     "header": "Houd je bedrijf bij op één plek",
        //                     "paragraph": "Met PocketCFO kun je verschillende bankrekeningen verbinden, zelfs van verschillende banken, in verschillende landen. Zodat je één overzicht hebt, op één plek."
        //                 },
        //                 "pricing": {
        //                     "header": "Vaste prijzen, geen beheerkosten",
        //                     "paragraph": "Begin vandaag gratis, of start met een knal met je Pro-plan!",
        //                     "plans": [
        //                         {
        //                             "name": "Starter",
        //                             "featured": false,
        //                             "price": {"Monthly": "Gratis", "Annually": "$0"},
        //                             "description": "Eerste maand gratis, van ons! Na de proefmaand wordt dit plan omgezet in het Pro-plan",
        //                             "button": {
        //                                 "label": "Begin Nu",
        //                                 "href": "/consentflow/?plan_id=starter&lang=nl"
        //                             },
        //                             "features": [
        //                                 {
        //                                     "name": "Inkomstenstromen",
        //                                     "desc": "Laat zien waar je inkomsten vandaan komen"
        //                                 },
        //                                 {
        //                                     "name": "Uitgavencategorisatie",
        //                                     "desc": "Laat zien waar je je geld aan uitgeeft"
        //                                 },
        //                                 {
        //                                     "name": "Topklanten",
        //                                     "desc": "Laat je grootste inkomstenbronnen zien"
        //                                 },
        //                                 {
        //                                     "name": "Cashflowprojectie voor 3 maanden",
        //                                     "desc": "Voorspelt je kassaldo 3 maanden in de toekomst"
        //                                 }
        //                             ],
        //                             "logomarkClassName": "fill-gray-300"
        //                         },
        //                         {
        //                             "name": "Pro",
        //                             "featured": false,
        //                             "price": {"Monthly": "14,95", "Annually": "$70"},
        //                             "description": "Begin proactief vooruit te kijken en krijg suggesties met ons Pro-plan",
        //                             "button": {
        //                                 "label": "Begin Nu",
        //                                 "href": "/consentflow/?plan_id=pro&lang=nl"
        //                             },
        //                             "features": [
        //                                 {
        //                                     "name": "Inkomstenstromen",
        //                                     "desc": "Laat zien waar je inkomsten vandaan komen"
        //                                 },
        //                                 {
        //                                     "name": "Uitgavencategorisatie",
        //                                     "desc": "Laat zien waar je je geld aan uitgeeft"
        //                                 },
        //                                 {
        //                                     "name": "Topklanten",
        //                                     "desc": "Laat je grootste inkomstenbronnen zien"
        //                                 },
        //                                 {
        //                                     "name": "Klanteninzichten",
        //                                     "desc": "Krijg verschillende inzichten over je klanten"
        //                                 },
        //                                 {
        //                                     "name": "Uitgaventrends",
        //                                     "desc": "Voeg inzichten toe aan je uitgaven"
        //                                 },
        //                                 {
        //                                     "name": "Winstgevendheid",
        //                                     "desc": "Krijg inzicht in je winst"
        //                                 },
        //                                 {
        //                                     "name": "BTW- en inkomstenbelastingprojecties",
        //                                     "desc": "Voorspellingen van wat je belasting zal zijn"
        //                                 },
        //                                 {
        //                                     "name": "Cashflowprojectie voor 12 maanden",
        //                                     "desc": "Voorspelt je kassaldo 12 maanden in de toekomst"
        //                                 }
        //                             ],
        //                             "logomarkClassName": "fill-gray-500"
        //                         },
        //                         {
        //                             "name": "Pro AI",
        //                             "featured": true,
        //                             "price": {"Monthly": "54,95", "Annually": "$1,990"},
        //                             "description": "Praat met de data, in eenvoudige termen. Met dit plan kun je je PocketCFO-data in mensentaal vragen stellen",
        //                             "button": {
        //                                 "label": "Binnenkort beschikbaar",
        //                                 "href": ""
        //                             },
        //                             "features": [
        //                                 {
        //                                     "name": "Inkomstenstromen",
        //                                     "desc": "Laat zien waar je inkomsten vandaan komen"
        //                                 },
        //                                 {
        //                                     "name": "Uitgavencategorisatie",
        //                                     "desc": "Laat zien waar je je geld aan uitgeeft"
        //                                 },
        //                                 {
        //                                     "name": "Topklanten",
        //                                     "desc": "Laat je grootste inkomstenbronnen zien"
        //                                 },
        //                                 {
        //                                     "name": "Klanten Diversiteit",
        //                                     "desc": ""
        //                                 },
        //                                 {
        //                                     "name": "Uitgaventrends",
        //                                     "desc": "Voeg inzichten toe aan je uitgaven"
        //                                 },
        //                                 {
        //                                     "name": "Winstgevendheid",
        //                                     "desc": "Krijg inzicht in je winst"
        //                                 },
        //                                 {
        //                                     "name": "BTW- en inkomstenbelastingprojecties",
        //                                     "desc": "Voorspellingen van wat je belasting zal zijn"
        //                                 },
        //                                 {
        //                                     "name": "Cashflowprojectie voor 18 maanden",
        //                                     "desc": "Voorspelt je kassaldo 18 maanden in de toekomst"
        //                                 },
        //                                 {
        //                                     "name": "Praat interactief en eenvoudig met je data",
        //                                     "desc": ""
        //                                 }
        //                             ],
        //                             "logomarkClassName": "fill-cyan-500"
        //                         }
        //                     ]
        //                 },
        //                 "faqs": {
        //                     "header": "Veelgestelde vragen",
        //                     "paragraph": "Als je nog andere vragen hebt, ",
        //                     "paragraph_link": "neem contact met ons op",
        //                     "paragraph_link_url": "mailto:hello@pocketcfo.io",
        //                     "questions": [
        //                         [
        //                             {
        //                                 "question": "Hoe verschilt dit van traditionele methoden die door accountants worden gebruikt?",
        //                                 "answer": "Traditionele boekhouding houdt vaak in dat je wacht op jaarverslagen of handmatig gegevens samenstelt. Onze AI analyseert je dagelijkse banktransacties en biedt realtime inzichten zoals verkooptrends en optimalisatiemogelijkheden voor kosten. Het is als een CFO die voortdurend je financiën bewaakt, voor een fractie van de kosten"
        //                             },
        //                             {
        //                                 "question": "Wat voor inzichten zal ik krijgen over mijn verkoop?",
        //                                 "answer": "We gaan verder dan basiscijfers. Onze AI analyseert trends in je inkomstenstromen, identificeert seizoensfluctuaties en helpt je toekomstige inkomsten te voorspellen. Dit stelt je in staat om weloverwogen beslissingen te nemen over pricing, marketing en resourceallocatie"
        //                             }
        //                         ],
        //                         [
        //                             {
        //                                 "question": "Heb ik nog steeds een accountant nodig?",
        //                                 "answer": "Je PocketCFO zal je regelmatig bruikbare inzichten over je bedrijf geven, in eenvoudige, duidelijke taal. Zodat je weloverwogen beslissingen kunt nemen. In sommige gevallen voel je je misschien meer op je gemak om deze inzichten te bespreken met je adviseurs of zelfs hen toegang te geven tot je PocketCFO. Zij kennen je bedrijf, maar hebben vaak niet de tijd om je tijdens het jaar te informeren. Zij zullen zeker doorgaan met het opstellen van je jaarverslag en/of belastingaangiften."
        //                             },
        //                             {
        //                                 "question": "Wat bedoel je met CO₂-impact van mijn bedrijf?",
        //                                 "answer": "Door je uitgavenpatronen te analyseren, kunnen we de milieu-impact schatten die gepaard gaat met je leveranciers. Dit stelt je in staat om weloverwogen keuzes te maken voor een duurzamer bedrijfsmodel."
        //                             }
        //                         ],
        //                         [
        //                             {
        //                                 "question": "Kan je AI werkelijk mijn banksaldo 18 maanden van tevoren voorspellen?",
        //                                 "answer": "Onze AI-voorspellingen zijn gebaseerd op je historische transactiegegevens en branchetrends. Hoewel het geen gegarandeerde glazen bol is, kunnen deze voorspellingen een krachtig hulpmiddel zijn voor financiële planning. Hoe verder de voorspelling, hoe groter de kans op variatie, maar het helpt je potentiële tekorten of overschotten van tevoren te identificeren, zodat je actie kunt ondernemen."
        //                             },
        //                             {
        //                                 "question": "Is mijn financiële data veilig bij PocketCFO?",
        //                                 "answer": "Absoluut. We hanteren beveiligingsprotocollen die voldoen aan de industrienormen om je financiële informatie te beschermen. We hebben alleen toegang tot gegevens die je autoriseert via beveiligde verbindingen met je bank, zoals voorgeschreven door de",
        //                             }
        //                         ]
        //                     ]
        //                 }
        //             },
        //
        //             footer: {
        //                 motto: "Een fractionele CFO voor een fractie van de kosten",
        //                 legal_column_header: "Juridisch",
        //                 general_terms_a_tag: '<a href="/general-terms" className="">Algemene Voorwaarden</a>',
        //                 privacy_policy_a_tag: '<a href="#" className="text-sm leading-6 text-gray-300 hover:text-white">Privacybeleid</a>',
        //                 contact_column_header: "Contact"
        //             }
        //
        //         }
        //     }
        // }
    });

export default i18n;
