import { AppStoreLink } from './AppStoreLink'
import { CircleBackground } from './CircleBackground'
import { Container } from './Container'
import {Button} from "./Button";
import {useTranslation} from "react-i18next";

export function CallToAction() {
  const { t } = useTranslation();

  return (
    <section
      id="get-free-shares-today"
      className="relative overflow-hidden bg-gray-900 py-20 sm:py-28"
    >
      <div className="absolute left-20 top-1/2 -translate-y-1/2 sm:left-1/2 sm:-translate-x-1/2">
        <CircleBackground color="#fff" className="animate-spin-slower" />
      </div>
      <Container className="relative">
        <div className="mx-auto max-w-md sm:text-center">
          <h2 className="text-3xl font-medium tracking-tight text-white sm:text-4xl">
            {t(globalThis.AUDIENCE+'.homepage.cta.header')}
          </h2>
          <p className="mt-4 text-lg text-gray-300">
            {t(globalThis.AUDIENCE+'.homepage.cta.paragraph')}
          </p>
          <div className="mt-8 flex justify-center">
            <Button
                href="#pricing"
                color="cyan"
            >
              <span className="mx-1.5">{t(globalThis.AUDIENCE+'.general.try_it_CTA')}</span>
            </Button>
          </div>
        </div>
      </Container>
    </section>
  )
}
