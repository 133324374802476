"use client";

import {useRef, useState} from "react";

import {AnimatePresence, motion} from "framer-motion";
import {useTranslation} from "react-i18next";
import {useSearchParams} from "react-router-dom";

function AudienceSelectorLinks() {
    const {t} = useTranslation();
    let [hoveredIndex, setHoveredIndex] = useState(null);
    let timeoutRef = useRef(null);

    const [searchParams, setSearchParams] = useSearchParams();

    return [
        [t(globalThis.AUDIENCE + ".navbar.links.entrepreneurs"), "/", null],
        [
            t(globalThis.AUDIENCE + ".navbar.links.financeProfessionals"),
            "/?for=finance-professionals",
            "finance-professionals",
        ],
        [
            t(globalThis.AUDIENCE + ".navbar.links.investors"),
            "/?for=investors-lenders",
            "investors-lenders",
        ],
    ].map(([label, href, param], index) => (
        <a
            key={label}
            href={href}
            className="relative -mx-1.5 -my-2 rounded-lg px-3 py-1 text-sm text-gray-700 transition-colors delay-150 hover:text-gray-900 hover:delay-0"
            onMouseEnter={() => {
                if (timeoutRef.current) {
                    window.clearTimeout(timeoutRef.current);
                }
                setHoveredIndex(index);
            }}
            onMouseLeave={() => {
                timeoutRef.current = window.setTimeout(() => {
                    setHoveredIndex(null);
                }, 200);
            }}
        >
            <AnimatePresence>
                {(hoveredIndex === index || searchParams.get("for") === param) && (
                    <motion.span
                        className="absolute inset-0 rounded-lg bg-blue-100"
                        layoutId="hoverBackground"
                        initial={{opacity: 0}}
                        animate={{opacity: 1, transition: {duration: 0.15}}}
                        exit={{
                            opacity: 0,
                            transition: {duration: 0.15},
                        }}
                    />
                )}
            </AnimatePresence>
            <span
                className={`relative z-10 ${searchParams.get("for") === param ? "text-primary" : ""}`}
            >
        {label}
      </span>
        </a>
    ));
}


export function AudienceSelector() {
    return (
        <div className="flex items-center gap-4 bg-gray-100 p-4 rounded-xl">
            {AudienceSelectorLinks()}
        </div>
    )
}
